import axios from '@/plugins/axios'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_COMITE;

class Comite
{
	solicitudes() {
        return axios.get(baseURL+'/solicitud')
    }

    solicitudes_automatizadas() {
        return axios.get(baseURL+'/solicitudes-automatizadas')
    }

    solicitud(id) {
        return axios.get(baseURL+`/solicitud/scoring/${id}`)
    }

    votacion(payload) {
        return axios.post(baseURL+'/votacion', payload)
    }
    
    getVotacion(id) {
        return axios.get(baseURL+`/votacion/${id}`)
    }

    historico(options) {
        return axios.get(utils.url_options(baseURL+'/comite', options));
    }
    
    historico_solicitud(solicitud) {
        return axios.get(utils.url_options(baseURL+`/comite/solicitud/${solicitud}`));
    }

    obtener_comentarios(solicitud) {
        return axios.get(baseURL+'/votacion-historico/'+solicitud);
    }

    reglas_automatizacion(){
        return axios.get(baseURL+'/automatizacion/reglas');
    }

    guardar_reglas_automatizacion(id, options){
        return axios.put(baseURL+`/automatizacion/regla/${id}`, options);
    }

    //Urls Cesion de creditos
    solicitudesCesionCreditos(options) {
        return axios.get(utils.url_options(baseURL+'/cesion-creditos/solicitudes', options));
    }

    get_solicitudes_a_ceder() {
        return axios.get(baseURL+`/cesion-creditos/solicitudes-ceder`)
    }

    ceder_solicitud(options, solicitud_id) {
        return axios.post(baseURL+`/cesion-creditos/solicitudes-ceder/${solicitud_id}`, options)
    }

    obtener_historico_cesion_creditos(options) {
        return axios.get(utils.url_options(baseURL+'/cesion-creditos/comites', options));
    }

    obtener_historico_solicitud_cesion_creditos(solicitud) {
        return axios.get(utils.url_options(baseURL+`/cesion-creditos/comite/solicitud/${solicitud}`));
    }

    obtener_historico_solicitud_organizacion_cesion_creditos(solicitud, organizacion_id) {
        return axios.get(utils.url_options(baseURL+`/cesion-creditos/comite/solicitud/${solicitud}/organizacion/${organizacion_id}`));
    }

    votacion_cesion_creditos(payload) {
        return axios.post(baseURL+'/cesion-creditos/votar', payload)
    }

    get_votacion_solicitud_cesion_creditos(solicitud) {
        return axios.get(baseURL+`/cesion-creditos/votacion/solicitud/${solicitud}`)
    }

    get_usuarios() {
        return axios.get(baseURL+'/usuarios')
    }
    enviar_usuario(payload) {
        return axios.post(baseURL+'/usuario', payload)
    }

    get_usuarios_cesion() {
        return axios.get(baseURL+'/cesion-creditos/usuarios')
    }
    enviar_usuario_cesion(payload) {
        return axios.post(baseURL+'/cesion-creditos/usuario', payload)
    }

}

export default new Comite();
<template>
	<div class="container-md text-center">
		<h2 class="text-center">Usuarios</h2>
			
		<div class="row">
			<div class="col-sm">
				<table class="table">
					<thead class="thead-dark">
						<tr>
						<th scope="col">ID account</th>
						<th scope="col">Nombre</th>
						<th scope="col">Email</th>
						<th scope="col">Creacion</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(usuario, index) of usuarios" :key="usuario.id" >
							<th scope="row">{{usuario.id}}</th>
							<td>{{usuario.nombre}}</td>
							<td>{{usuario.email}}</td>
							<td>{{usuario.created_at}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="p-5">
			<fieldset class="scheduler-border">
			<legend class="scheduler-border text-primary">Crear usuario</legend>
				<form id="form" @submit.prevent="submit" class="needs-validation">
					<div class="row">
						<div class="col-sm">
							
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="idAccount">Id account</label>
									<input type="number" class="form-control" id="idAccount" v-model="idAccount" required>
								</div>
								<div class="form-group col-md-6">
									<label for="nombre">Nombre</label>
									<input type="text" class="form-control" id="nombre" v-model="nombre" required>
								</div>
							</div>
							<div class="form-row">
								<div class="form-group col-md-6">
									<label for="email">Email</label>
									<input type="email" class="form-control" id="email" v-model="email" required>
								</div>
							</div>

							<button type="submit" class="btn btn-primary mb-2">Guardar</button>

						</div>	
					</div>
				</form>
				<a href="#" class="badge badge-info">Adicional se deben dar permisos a los usuario en account</a>
			</fieldset>
		</div>
	</div>
</template>

<script type="text/javascript">
	import apiComite from '@/apps/comite/api/comite'
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		},
		
		data: () => ({
			usuarios: [],
			idAccount: null,
			nombre: null,
			email: null,
			modalConfig: {
				width: '50vw',
				close: true,
			},

		}),
		
		mounted: async function() {
			const resUsuarios = await apiComite.get_usuarios();
			this.usuarios = resUsuarios.data
		},

		methods: {
			
			submit: async function (event) {
				form.classList.add('was-validated');
				event.preventDefault();
          		event.stopPropagation();
				
				try{
					var status = true;
					for (var usuario of this.usuarios) {
						if(usuario.id == this.idAccount){
							this.$notify({
								group: 'alert'
								,type: 'error'
								,title: 'ID account'
								,text: 'Ya se encuentra este id: '+this.idAccount
								,duration: 4000
							})
							status = false
							this.idAccount = null
						}
						if(usuario.email == this.email){
							this.$notify({
								group: 'alert'
								,type: 'error'
								,title: 'Email'
								,text: 'Ya se encuentra este email: '+this.email
								,duration: 4000
							})
							status = false
							this.email = null
						}
					};

					if(status){
						await apiComite.enviar_usuario(
							{
								account_id: this.idAccount,
								nombre: this.nombre,
								email: this.email
							}
						).then( result =>  {
							if(result.status == 200 && result.data.succes){ 
								this.usuarios.push({
									"id": this.idAccount,
									"nombre": this.nombre,
									"email": this.email,
									created_at: result.data.usuario.created_at
								})

								this.idAccount = null
								this.nombre = null
								this.email = null

								this.$notify({
									group: 'alert'
									,type: 'success'
									,title: 'Reglas'
									,text: 'Datos guardados...'
									,duration: 4000
								})
							}
						})
					}
					
				}catch(err){

					if(err.response?.status == 422){
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: err.response?.data?.errors[0]
							,duration: 4000
						})
					}

					if(err.response?.status == 401){
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: 'Acceso no autorizado'
							,duration: 4000
						})
					}else
					if(err.response?.status == 400){
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: err.response?.data?.message
							,duration: 4000
						})
					}else{
						this.$notify({
							group: 'alert'
							,type: 'error'
							,title: 'Error'
							,text: 'Ocurrió un error, favor de intentar más tarde.'
							,duration: 4000
						})
					}
					
				}
			},

			
			
		},
		watch: {
			// entidadesSelect: function (newEntidades, oldVal) {
			// 	this.nuevas_entidades(newEntidades)
			// },
		}
	}
</script>

<style>

	fieldset.scheduler-border {
		margin: 0 0 1.5em 0 !important;
		-webkit-box-shadow: 0px 0px 0px 0px #000;
		box-shadow: 0px 0px 0px 0px #000;
	}

	legend.scheduler-border {
		font-size: 1.2em !important;
		font-weight: bold !important;
		text-align: left !important;
		width: auto;
		padding: 0 10px;
		border-bottom: none;
		margin-top: -15px;
		background-color: white;
		color: black;
	}

	div.scroll {
		background-color: lightblue;
		width: 100%;
		height: auto;
		max-height: 100px!important;
		overflow: scroll;
	}
</style>